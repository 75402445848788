import React, { Component, Fragment } from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import Logo from '../../images/logo';
import MobileMenu from '../../images/mobile-menu';
import Youtube from '../../images/youtube';
import Instagram from '../../images/instagram';
import Facebook from '../../images/facebook';

export default class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            url: null,
            showStoreMenu: false,
        };

        this.changeMenuState = this.changeMenuState.bind(this);
    }

    componentDidMount() {
        const url = window.location.pathname;
        this.setState({ url });
    }

    changeMenuState() {
        this.setState(({ menuOpen }) => ({
            menuOpen: !menuOpen,
            showStoreMenu: false,
        }));
    }

    render() {
        const { showStoreMenu } = this.state;

        return (
            <Fragment>
                <header className="shadow-md">
                    <div>
                        <Link to="/">
                            <Logo />
                        </Link>
                        <nav>
                            <Link to="/" className={this.state.url === '/' ? 'active' : ''}>
                                Coaching
                            </Link>
                            <Link
                                to="/nutrition-hub"
                                className={this.state.url === '/nutrition-hub' ? 'active' : ''}
                            >
                                Nutrition
                            </Link>
                            <Link
                                to="/newsletter"
                                className={
                                    this.state.url && this.state.url.indexOf('/newsletter') === 0
                                        ? 'active'
                                        : ''
                                }
                            >
                                Newsletter
                            </Link>
                            <Link
                                to="/testimonials"
                                className={
                                    this.state.url && this.state.url.indexOf('/testimonials') === 0
                                        ? 'active'
                                        : ''
                                }
                            >
                                Testimonials
                            </Link>
                            <Link
                                to="/about"
                                className={
                                    this.state.url && this.state.url.indexOf('/about') === 0
                                        ? 'active'
                                        : ''
                                }
                            >
                                About
                            </Link>
                        </nav>
                        <nav className="position-right">
                            <a href="https://app.jclarkefitness.com/login/">Login</a>
                        </nav>
                        <div className="social-icons">
                            <OutboundLink
                                href="https://www.youtube.com/channel/UC0TaX8wSzA7ogkVmmNEkURA"
                                target="blank"
                            >
                                <Youtube width="27.9" height="19.8" />
                            </OutboundLink>
                            <OutboundLink
                                href="https://www.instagram.com/jclarke_fitness/"
                                target="blank"
                            >
                                <Instagram width="23.4" height="23.4" />
                            </OutboundLink>
                            <OutboundLink
                                href="https://www.facebook.com/JClarkeFitness/"
                                target="blank"
                            >
                                <Facebook width="21.6" height="21.6" />
                            </OutboundLink>
                        </div>
                        <MobileMenu onClick={this.changeMenuState} width="40" height="40" />
                    </div>
                </header>
                <div className={`mobile-menu${this.state.menuOpen ? ' open' : ''}`}>
                    <nav className={showStoreMenu && 'slide-out'}>
                        <Link to="/">Coaching</Link>
                        <Link to="/nutrition-hub">Nutrition</Link>
                        <Link to="/newsletter">Newsletter</Link>
                        <Link to="/testimonials">Testimonials</Link>
                        <Link to="/about">About</Link>
                        <OutboundLink href="https://app.jclarkefitness.com/login">
                            Login
                        </OutboundLink>
                    </nav>
                    <div className="social-icons">
                        <OutboundLink
                            href="https://www.youtube.com/channel/UC0TaX8wSzA7ogkVmmNEkURA"
                            target="blank"
                        >
                            <Youtube />
                        </OutboundLink>
                        <OutboundLink
                            href="https://www.instagram.com/jclarke_fitness/"
                            target="blank"
                        >
                            <Instagram />
                        </OutboundLink>
                        <OutboundLink
                            href="https://www.facebook.com/JClarkeFitness/"
                            target="blank"
                        >
                            <Facebook />
                        </OutboundLink>
                    </div>
                </div>
                <style jsx>{`
                    header {
                        background-color: #263238;
                        height: 80px;
                        width: 100%;
                        position: relative;
                        z-index: 800;

                        & > div {
                            width: 100%;
                            padding: 0 24px;
                            margin: 0 auto;
                            max-width: 1240px;
                            box-sizing: border-box;
                        }

                        & :global(a) {
                            cursor: pointer;
                        }

                        & :global(svg.logo) {
                            display: block;
                            float: left;
                            margin-top: 10px;
                        }

                        & :global(nav) {
                            float: left;
                            margin: 28px 0 0 40px;
                            display: block;
                            line-height: 24px;

                            & > :global(a),
                            & :global(span.menu-dropdown) {
                                color: #fff;
                                opacity: 0.7;
                                text-transform: uppercase;
                                text-decoration: none;

                                & + :global(a),
                                & + :global(span.menu-dropdown) {
                                    margin-left: 24px;
                                }
                            }

                            & > :global(a:hover),
                            & > :global(a.active),
                            & :global(span.menu-dropdown:hover),
                            & :global(span.menu-dropdown.active) {
                                opacity: 1;
                            }
                        }

                        & :global(nav.position-right) {
                            float: right;
                            margin-left: 24px;
                        }

                        & :global(.social-icons) {
                            float: right;
                            margin-top: 20px;
                            padding-right: 24px;
                            border-right: 1px solid rgba(255, 255, 255, 0.2);
                        }

                        & :global(.mobile-menu) {
                            float: right;
                            margin-top: 20px;
                            cursor: pointer;
                            opacity: 0.7;

                            &:hover {
                                opacity: 1;
                            }
                        }

                        & :global(.social-icons) {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            height: 40px;
                            width: 104px;

                            & > :global(a) {
                                display: inline-block;
                                opacity: 0.7;
                                will-change: opacity;
                                transition: opacity 0.1s ease;

                                &:hover {
                                    opacity: 1;
                                }
                            }

                            & :global(svg) {
                                display: block;
                            }
                        }
                    }

                    .mobile-menu {
                        width: 100%;
                        background-color: rgba(38, 50, 56, 0.96);
                        padding: 104px 0 24px;
                        position: absolute;
                        top: 0;
                        z-index: 700;
                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
                        will-change: transform;
                        transform: translateY(-600px) translateZ(0);
                        transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1),
                            transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);

                        &.open {
                            transform: translateY(0) translateZ(0);
                            opacity: 1;
                        }

                        & button {
                            padding: 0;
                            border: none;
                            font: inherit;
                            color: inherit;
                            background-color: transparent;
                            cursor: pointer;

                            & svg {
                                display: inline-block;
                                vertical-align: bottom;
                                fill: rgba(255, 255, 255, 0.7);
                            }
                        }

                        & :global(a),
                        & button {
                            display: block;
                            margin: 16px auto;
                            text-align: center;
                            color: #fff;
                            text-decoration: none;
                            text-transform: uppercase;
                            text-shadow: 0 0px 6px rgba(0, 0, 0, 0.38),
                                0 3px 6px rgba(0, 0, 0, 0.46);
                        }

                        & :global(.social-icons) {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin: 20px auto 0;
                            padding-top: 10px;
                            border-top: 1px solid rgba(255, 255, 255, 0.2);
                            max-width: 140px;
                        }

                        & nav:not(.store-menu) {
                            transform: translateX(0px);
                            transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);
                            will-change: transform;
                        }

                        & nav.slide-out {
                            transform: translateX(-100vw);
                        }
                    }

                    .store-menu {
                        position: absolute;
                        top: 0;
                        padding-top: 104px;
                        left: 50%;
                        will-change: transform;
                        transform: translateX(calc(-50% + 100vw));
                        transition: transform 0.2s cubic-bezier(0.4, 0, 0.2, 1);

                        &.slide-in {
                            transform: translateX(-50%);
                        }
                    }

                    @media (min-width: 800px) {
                        header {
                            & > div {
                                padding: 0 32px;
                            }
                        }
                    }

                    @media (max-width: 860px) {
                        header {
                            & :global(nav),
                            & :global(.social-icons) {
                                display: none;
                            }
                        }
                    }

                    @media (min-width: 860px) {
                        header :global(.mobile-menu),
                        .mobile-menu {
                            display: none;
                        }
                    }

                    @media print {
                        header,
                        .mobile-menu {
                            display: none !important;
                        }
                    }
                `}</style>
            </Fragment>
        );
    }
}
